.MuiDivider-root {
	border: none;
	height: 2px;
	margin: 25px !important;
	/* margin: 0; */
	flex-shrink: 0;
	background-color: rgb(255, 255, 255) !important;
}

.title {
	font-size: 40px;
	color: white;
}

.subtitle_small {
	font-size: 25px;
}

.btn-primary {
	color: #fff;
	background-color: #00000000;
	border-color: #ffffff;
}

@media (min-width: 1200px) {
	.subtitle_small {
		display: none;
	}
	.subtitle_large {
		font-size: 25px;
	}
}

@media (max-width: 1200px) {
	.subtitle_large {
		display: none;
	}
	.subtitle_small {
		font-size: 25px;
	}
}

@media (min-width: 800px) and (max-width: 1000px) {
	.img_logo {
		right: 50px;
		position: relative;
	}
}

/* iPhone 4 (landscape) ----------- */
@media only screen and (max-device-width: 360px) and (max-device-height: 670px) {
	/* Styles */
	.title {
		font-size: 22px;
		color: white;
	}
	.subtitle_small {
		font-size: 17px;
	}

	.text_under_map {
		font-size: 13px;
	}
}

@media (min-width: 1000px) {
	.img_logo {
		height: 250px !important;
		width: 250px !important;
		/* position: relative;
		right: 48px; */
	}
}
