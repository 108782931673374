.servizi {
	margin: 0px !important;
	padding-top: 10px;
}

.skills-text {
	color: #1e1e1e;
	font-weight: 600;
	padding-left: 50px;
}

.about-mf .box-shadow-full {
	padding-top: 4rem;
	padding-bottom: 4rem;
}

.about-mf .about-img {
	margin-bottom: 2rem;
	filter: drop-shadow(8px 8px 8px #333333);
}

.about-mf .about-img img {
	margin-left: 5px;
}

.skill-mf span {
	color: #4e4e4e;
}

@media (min-width: 1000px) {
	.img_logo {
		height: 250px !important;
		width: 250px !important;
	}
}

@media (max-width: 1000px) {
	.img_logo {
		height: 200px !important;
		width: 200px !important;
	}
}
