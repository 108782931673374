input[type='text'],
input[type='email'],
select,
textarea {
	width: 100%;
	padding: 12px;
	border: 1px solid #ccc;
	border-radius: 4px;
	box-sizing: border-box;
	margin-top: 6px;
	margin-bottom: 16px;
	resize: vertical;
}

textarea.message-field {
	height: 206px;
}

input[type='submit'] {
	background-color: #4caf50;
	color: white;
	padding: 12px 20px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
}

input[type='submit']:hover {
	background-color: #45a049;
}

.sucsess {
	margin: 20px 0;
	border: 3px solid green;
	background-color: #2a6020;
	padding: 7px;
	border-radius: 4px;
	color: white;
	filter: drop-shadow(2px 4px 6px black);
}

.error {
	margin: 20px 0;
	border: 3px solid red;
	background-color: #a80000;
	padding: 7px;
	border-radius: 4px;
	color: white;
	filter: drop-shadow(2px 4px 6px black);
}

.checkbox {
	width: 20px;
	height: 20px;
}

.box-check-sub {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.text_check {
	padding-left: 14px;
	position: relative;
	top: -2px;
}

.box-check {
	display: flex;
	padding-left: 25px;
	position: relative;
}
