.cards {
	padding: 0px;
}

.MuiCardContent-root {
	padding: 11px;
	text-align: center;
	padding: 6px;
	vertical-align: middle;
	text-transform: uppercase;
}

.MuiTypography-h5 {
	font-size: 20px !important;
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
	font-weight: 400;
	line-height: 1.334;
	letter-spacing: 0em;
}

.MuiTypography-root {
	margin: 6px;
}
