.view_site {
	content: url('https://api.iconify.design/carbon:view-filled.svg?height=32');
	vertical-align: sub;
}

.view_site:hover {
	content: url('https://api.iconify.design/carbon:view-filled.svg?color=white&height=32');
	vertical-align: sub;
}

/* .img_harvard {
	height: 20px;
}

.img_harvard:hover {
	height: 20px;
} */

.label_w {
	background: black;
	width: 100%;
	padding-top: 6px;
	text-align-last: center;
	color: white;
	text-align: center;
	padding-bottom: 6px;
}

.a_label_w {
	color: white;
}